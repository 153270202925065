<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Social from "@/services/Social";
import Swal from "sweetalert2";
import _ from 'underscore';

export default {
    components: { Layout, PageHeader },
    page: {
        title: "Social",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    watch: {
        'filterInput': {
            handler: function () {
                this.filterClicked = false; 
            },
            deep: true
        }
    },
    data() {
        return {
            title: "Social",
            items: [
                {
                    text: "Fan Experience",
                },
                {
                    text: "Social",
                    active: true,
                },
            ],
            filterInput: {
                pointsFrom: '',
                pointsTo: '',
                levelFrom: '',
                levelTo: '',
            },
            error: null,
            showModal: false,
            modalData: {},
            tableData: [],
            showErrorMessage: false,
            filterClicked: false,
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: ['nickname', 'points', 'level'],
            sortBy: "timestamp",
            showTable: false,
            sortDesc: true,
            fields: [
                {
                    key: "nickname",
                    label: "Nickname",
                    sortable: true,
                },
                {
                    key: "points",
                    label: "Points",
                    sortable: false,
                },
                {
                    key: "level",
                    label: "Level",
                    sortable: false,
                },
            ],
            isBusy: false,
        };
    },
    computed: {
        isFormValid() {
            return (
                this.filterInput.pointsFrom.trim() !== '' ||
                this.filterInput.pointsTo.trim() !== '' ||
                this.filterInput.levelFrom.trim() !== '' ||
                this.filterInput.levelTo.trim() !== ''
            );
        },
        rows() {
            return this.tableData.length;
        },
    },
    mounted() {
        this.totalRows = this.items.length;
    },
    methods: {
        async getSocialCustomers() {
            try {
                this.toggleBusy();
                const filters = this.getFilters();
                const response = await Social.getSocialCustomers(filters);
                console.log("API Response:", response.data);
                this.tableData = response.data.data;
                console.log("Table Data:", this.tableData); 
                this.totalRows = this.tableData.length;
                this.toggleBusy();
                this.toggleTableVisibility(); // Show the table
            } catch (error) {
                this.toggleBusy();
                console.error("Error fetching social customers:", error);
                this.error = error.response?.data?.error || error.message;
                this.tableData = [];
            }
        },
        submitForm() {
            this.filterClicked = true;
            if (!this.isFormValid) {
                this.showErrorMessage = true;
                return;
            }
            this.getSocialCustomers();
            this.showErrorMessage = false;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        toggleTableVisibility() {
            this.showTable = true; // Show the table
        },
        resetFilters() {
            this.filterInput = {
                pointsFrom: '',
                pointsTo: '',
                levelFrom: '',
                levelTo: '',
            };
            this.submitForm();
        },
        getFilters() {
            let filterString = '';
            if (this.filterInput.pointsTo) {
                filterString += `&points_to=${parseInt(this.filterInput.pointsTo)}`;
            }
            if (this.filterInput.pointsFrom) {
                filterString += `&points_from=${parseInt(this.filterInput.pointsFrom)}`;
            }
            if (this.filterInput.levelTo) {
                filterString += `&level_to=${parseInt(this.filterInput.levelTo)}`;
            }
            if (this.filterInput.levelFrom) {
                filterString += `&level_from=${parseInt(this.filterInput.levelFrom)}`;
            }
            return filterString.substring(1); 
        },
        toggleBusy() {
            this.isBusy = !this.isBusy;
        }
    },
    middleware: "authentication",
};
</script>

 <template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col-md-12">
                                <div class="custom-accordion">
                                    <a class="text-body fw-semibold pb-2 d-block" data-toggle="collapse" href="javascript: void(0);" role="button" aria-expanded="false" v-b-toggle.categories-collapse>
                                        <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
                                    </a>
                                    <b-collapse visible id="categories-collapse">
                                        <div class="card p-2 border shadow-none">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-3">
                                                    <b-form-group label="Points From" label-for="formrow-serial-input" class="mb-3">
                                                        <b-form-input v-model.trim="filterInput.pointsFrom" type="text" id="pointsFrom" aria-describedby="pointsFrom-feedback"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-sm-12 col-md-3">
                                                    <b-form-group label="Points To" label-for="formrow-series-input" class="mb-3">
                                                        <b-form-input v-model.trim="filterInput.pointsTo" type="text" id="pointsTo" aria-describedby="pointsTo-feedback"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-sm-12 col-md-3">
                                                    <b-form-group label="Level From" label-for="formrow-rfid-input" class="mb-3">
                                                        <b-form-input v-model.trim="filterInput.levelFrom" type="text" id="levelFrom" aria-describedby="levelFrom-feedback"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                                <div class="col-sm-12 col-md-3">
                                                    <b-form-group label="Level To" label-for="formrow-rfid-input" class="mb-3">
                                                        <b-form-input v-model.trim="filterInput.levelTo" type="text" id="levelTo" aria-describedby="levelTo-feedback"></b-form-input>
                                                    </b-form-group>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="alert alert-danger" v-if="filterClicked && !isFormValid" style="width:60%">
                                                    Please fill in at least one of the input fields (Points From, Points To, Level From, Level To) before submitting.
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col d-inline-flex">
                                                    <button type="button" class="btn btn-success me-2 w-lg" @click="submitForm">Filter</button>
                                                    <button type="button" class="btn btn-primary w-lg" @click="resetFilters">Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body" v-if="showTable">
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive mb-0">
                            <b-table
                                :items="tableData"
                                :busy="isBusy"
                                :fields="fields"
                                responsive="sm"
                                :per-page="perPage"
                                :current-page="currentPage"
                                :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc"
                                :filter="filter"
                                :filter-included-fields="filterOn"
                                @filtered="onFiltered"
                                show-empty
                                empty-text="No Data Found"
                            >
                                <template #table-busy>
                                    <div class="text-center text-black my-2">
                                        <b-spinner class="align-middle text-primary"></b-spinner>
                                        <strong class="text-primary">Loading...</strong>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info">
                                    Showing {{ (currentPage - 1) * perPage + 1 }} to
                                    {{ (currentPage - 1) * perPage + rows }} of {{ rows }} entries
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="right"
                                    size="sm"
                                ></b-pagination>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center mt-4">
                        <strong>Use the filter to display the data</strong>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
